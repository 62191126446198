import * as rk from '../localization/resourceKeys';

const constants = /** @type {const} */ ({
  // brands that match bronson packaging. See public dir or @vwfs-bronson in node_modules
  VALID_UI_BRANDS: ['audi', 'vw', 'vw6', 'skoda'],
  DEFAULT_BRAND: 'Audi',
  DEFAULT_COUNTRY: 'USA',
  DEFAULT_UI_BRAND: 'audi',
  DEFAULT_VW_UI_BRAND: 'VW6',
  DEFAULT_AUDI_UI_BRAND: 'Audi',
  COUNTRY_CANADA: 'Canada',
  COUNTRY_USA: 'USA',
  CANADA_BALLOON_TYPE: 'VW Select',
  BRAND_MAPPING: {
    audi: 'Audi',
    vw: 'VW',
    vw6: 'VW',
    skoda: 'Skoda',
  },
  CUSTOMER_MAPPING: {
    primary: 'Primary',
    cobuyer: 'CoBuyer',
    guarantor: 'Guarantor',
    other: 'Other',
  },
  USA_STATES_REQUIRING_ALL_PARTY_SIGNATURES: ['KS', 'MO', 'NY', 'PA', 'TX', 'VT'],
  ERROR_REGEX: {
    // eslint-disable-next-line
    otherAddress: /^[a-zA-Z0-9-' ~"!.\\/:;<=>?@{|}\*\$\[\]^]*$/,
    zipCode: /(^\d.*$)|(^\d{5}-\d{4}$)/,
  },
  vw6: 'VW6',
  VALID_LANG: ['en-US', 'en-CA', 'fr-CA', 'en-us'],
  EN_US: 'en-US',
  EN_CA: 'en-CA',
  FR_CA: 'fr-CA',
  FRENCH: 'fr',
  SOURCE_ID: 'vci-dashboard-app',
  EXTERNAL_ID: 'vci-dashboard-app',
  PAYOFF_QUOTE_SOURCE: 'ConsumerInternet',
  PAYOFF_QUOTE_TYPE: 'CustomerBasicPayoff',
  VW_BRAND: 'VW',
  AUDI_BRAND: 'Audi',
  MY_AUDI_SOURCE: 'myAudi',
  MY_AUDI_MOBILE_SOURCE: 'myAudi-app',
  SEDAN_VEHICLE_TYPE: 'Sedan',
  MIN_CONTRACT_DATE: new Date(2016, 6, 1),
  PAST_DUE_PAYMENT_STATUS: 'Past Due',
  LOGOUT_TIMER: 120,
  AUDI_TELEPHONE: '+18882372834',
  VW_TELEPHONE: '+18004284034',
});

export const paymentConstants = /** @type {const} */ ({
  FEES: 'Fees',
  PAST_DUE_PAYMENT: 'past-due-payment',
  CURRENT_PAYMENT: 'current-payment',
  PRINCIPAL_PAYMENT: 'principal-only-payment',
  ONETIME_PAYMENT: 'one-time-payment',
  ONETIME_PENDING_PAYMENT_CANCEL: 'onetime',
  PREFILL: 'Prefill',
  ESTIMATE: 'Estimate',
  TRANSACTIONS_TABLE: 'Transactions Table',
  // Whenever updating LIMITS, Please update ERROR_MIN_PAYMENT & ERROR_MAX_PAYMENT in i18n
  PAYMENT_MAX_LIMIT: 100000,
  PAYMENT_MIN_LIMIT: 5,
  VISA: 'VISA',
  MSTR: 'MSTR',
  AMEX: 'AMEX',
  DISC: 'DISC',
  EOT_PAYMENT_MAX_LIMIT: 9999.99,
  EOT_PAYMENT_MIN_LIMIT: 5.01,
  EOT_PAYMENT_NOT_ALLOWED_TOP: 5,
  CHECKING_ACCOUNT_TYPE: 'checking',
  EOT_INVALID_SECURITY_CODE: 'invalid_creditcardsecuritycode',
  AUDI_PAYMENT_SOURCE: 'myAudi',
  VW_PAYMENT_SOURCE: 'vwc portal',
});

export const addOrEditAccountConstants = /** @type {const} */ ({
  EDIT_ACCOUNT_FROM_SELECT_PAYMENT_TYPE: 'edit-account-from-select-payment-type',
  EDIT_ACCOUNT_FROM_AUTO_PAY_ENROLL: 'edit-account-from-auto-pay-enroll',
  ADD_ACCOUNT_FROM_MAKE_PAYMENT_FLOW: 'add-account-from-make-payment-flow',
  ADD_ACCOUNT_FROM_AUTO_PAY_ENROLL: 'add-account-from-auto-pay-enroll',
  EDIT_ACCOUNT_FROM_PAYOFF_PAYMENT_LOAN: 'edit-account-from-payoff-payment-loan',
  ADD_ACCOUNT_FROM_LOAN_PAYOFF: 'add-account-from-loan-payoff',
});

export const payoffQuoteConstants = /** @type {const} */ ({
  LEASE: 'Lease',
  LOAN: 'Loan',
  BALLOON: 'Balloon',
  RETAIL_BALLOON: 'Balloon',
  PAYOFF: 'payoff',
  COP: 'COP',
  NOT_TERMINATED_STATUS: 'NotTerminated',
  PAYOFF_QUOTE_FLOW: 'PayoffQuoteFlow',
  LEASE_PAYOFFQUOTE_FLOW: 'LeasePayoffQuoteFlow',
  LOAN_PAYOFFQUOTE_FLOW: 'LoanPayoffQuoteFlow',
  LOAN_PAYOFFQUOTE_MAIL_FLOW: 'LoanMailPayoffQuoteFlow',
  BALLOON_PAYOFFQUOTE_FLOW: 'BalloonPayoffQuoteFlow',
  OTHER_ADDRESS_TYPE: 'other',
  BILLING_ADDRESS_TYPE: 'billing',
  USMAIL_DELIVERY_TYPE: 'USMail',
  FEDEX_DELIVERY_TYPE: 'FedEx',
  FEDEX_DELIVERY_AUTHORIZATION_TYPE: 'ACHFee',
  FEDEX_DELIVERY_ACCOUNT_TYPE: 'ByAccountNumber',
  FEDEX_DELIVERY_AUTHORIZATION_FEE: '15.00',
  OTHER_ADDRESS_ENTERED_TYPE: 'entered-address',
  OTHER_ADDRESS_SUGGESTED_TYPE: 'suggested-address',
  ELECTRONIC_TITLE_STATUS: 'ElectronicTitleReceived',
  PAYOFFQUOTE_PAYMENT_API_ERRORCODES_MAPPING: {
    payoffQuoteId_invalid: rk.POQ_PAYMENT_ERROR_QUOTEID_REQUIRED,
    payoff_quoteId: rk.POQ_PAYMENT_ERROR_QUOTEID_REQUIRED,
    account_number: rk.POQ_PAYMENT_ERROR_ACCOUNT_NUMBER,
    required_nameonbankaccount: rk.ERROR_EMPTY_NAMEONBANKACCOUNT,
    maxlength_nameonbankaccount: rk.ERROR_LENGTH_NAME,
    chars_nameonbankaccount: rk.ERROR_CHAR_NAME,
    required_bankaccountnumber: rk.ERROR_REQUIRED_ACCOUNT_NUMBER,
    maxlength_bankaccountnumber: rk.ERROR_NUMERIC_ACCOUNT_NUMBER,
    numeric_bankaccountnumber: rk.ERROR_NUMERIC_ACCOUNT_NUMBER,
    required_routingnumber: rk.ERROR_REQUIRED_ROUTING_NUMBER,
    length_routingnumber: rk.ERROR_LENGTH_ROUTING_NUMBER,
    blank_withdrawalamount: rk.POQ_PAYMENT_ERROR_EMPTY_PAYOFFAMOUNT,
    min_withdrawalamount: rk.POQ_PAYMENT_ERROR_MIN_PAYOFFAMOUNT,
    max_withdrawalamount: rk.POQ_PAYMENT_ERROR_MAX_PAYOFFAMOUNT,
    duplicate_payment: rk.POQ_PAYMENT_ERROR_DUPLICATE_PAYMENT,
    paymentDate_invalid: rk.POQ_PAYMENT_ERROR_DATE_INVALID,
    source_required: rk.POQ_PAYMENT_ERROR_SOURCE_REQUIRED,
    usMailFee_amount: rk.POQ_PAYMENT_ERROR_USMAIL_FEEAMOUNT,
    fedExFee_amount: rk.POQ_PAYMENT_ERROR_FEDEX_FEEAMOUNT,
    fedExFee_shouldBeZero: rk.POQ_PAYMENT_ERROR_FEDEX_FEESHOULDBEZERO,
    altAddress_required: rk.POQ_PAYMENT_ERROR_ALTADDRESS_REQUIRED,
    addressLineOne_required: rk.POQ_PAYMENT_ERROR_ADDRESSLINEONE_REQUIRED,
    addressCity_required: rk.POQ_PAYMENT_ERROR_ADDRESSCITY_REQUIRED,
    addressStateProvince_required: rk.POQ_PAYMENT_ERROR_ADDRESSSTATE_REQUIRED,
    address_validStateCodeRequired: rk.POQ_PAYMENT_ERROR_ADDRESSSTATE_INVALID,
    addressCountry_required: rk.POQ_PAYMENT_ERROR_ADDRESSCOUNTRY_REQUIRED,
    addressPostalCode_required: rk.POQ_PAYMENT_ERROR_ADDRESSPOSTALCODE_REQUIRED,
    titleDeliveryInfo_required: rk.POQ_PAYMENT_ERROR_DELIVERYINFO_REQUIRED,
    isBillingAddressFlaf_required: rk.POQ_PAYMENT_ERROR_BILLINGADDRESSFLAG_REQUIRED,
    mailType_required: rk.POQ_PAYMENT_ERROR_MAILTYPE_REQUIRED,
    fedExFeeOption_required: rk.POQ_PAYMENT_ERROR_FEDEXFEEOPTION_REQUIRED,
  },
});

export const errorCodes = /** @type {const} */ ({
  AUTOPAY_CREATE_CONFLICT: 'badRequst_autopay_create_conflict', // typo in the api
  AUTOPAY_DELETE_CONFLICT: 'badRequst_autopay_delete_conflict', // typo in the api
});

export const mediumScreenWidth = 960;
export const mobileScreenWidth = 720;

export const bannerPriority = /** @type {const} */ ({
  TITLE_RELEASE_BANNER: 1,
  DISASTER_BANNER: 2,
  LATE_PAYMENT_BANNER: 3,
  PAYMENT_REMINDER_BANNER: 4,
  PAYMENT_CONFIRMATION_BANNER: 5,
  CDK_OUTAGE_BANNER: 6,
  DEFAULT: Number.MAX_SAFE_INTEGER,
});

export const adobeTaggingConstants = {
  MAKE_A_PAYMENT: {
    formName: 'make-a-payment',
    formType: 'payment',
    formStartingPoint: 'make-a-payment-modal',
  },
  EVENT_NAMES: {
    paymentStarted: 'PAYMENT_STARTED',
    paymentComplete: 'PAYMENT_COMPLETE',
    paymentCancelled: 'PAYMENT_CANCELLED',
    paymentError: 'PAYMENT_ERROR',
  },
  PAYMENT_EVENT_INFO: {
    paymentStarted: 'User begins payment Process',
    paymentComplete: 'User submits payment',
    paymentCancelled: 'User exits payment process',
    paymentError: 'Unsuccessful submission of payment occurs',
  },
  PROD_ENVIRONMENT_LONG: 'Production',
  PROD_ENVIRONMENT: 'prod',
  QA_ENVIRONMENT: 'QA',
  USD_CURRENCY: 'USD',
  CANADIAN_CURRENCY: 'CAD',
};

/* @see ../definition/vci-dashboard-component-definition.js */

export default /** @type {const} */ ({
  ...constants,
  ...paymentConstants,
  ...addOrEditAccountConstants,
  ...payoffQuoteConstants,
  ...errorCodes,
  ...bannerPriority,
  mediumScreenWidth,
  mobileScreenWidth,
  ...adobeTaggingConstants,
});
